import { Link } from 'react-router-dom';

let PHome = () => {
    return <div className="w-full">
        <section className="">

        </section>

        <section className="container mx-auto">
            <div className="text-center max-w-2x1 px-2 xl:px-4 mt-12 sm:mt-24 xl:mt-32 relative isolate">
                <h1 className="text-3xl sm:text-4xl xl:text-5xl"><span className="font-semibold">ConfEye</span> - Ваше окно в мир видеоконференций</h1>
                <p className="text-xl xl:text-3xl mt-6 text-gray-600 leading-8">Организуйте встречи легко и быстро, где бы вы ни были</p>
            </div>
            {/*
            <div>ConfEye - Видеоконференции без границ</div>
            <div>Высокое качество связи и удобство использования для всех ваших нужд</div>

            <div>ConfEye - Объединяя людей</div>
            <div>Быстрые и безопасныые видеоконференции для работы и общения</div>

            <div>ConfEye - Ваш новый стандарт видеоконференций</div>
            <div>Начните встречу в один клик и наслаждайтесь качественной связью</div>

            <div>ConfEye - Видеосвязь будущего</div>
            <div>Надежныые и простые видеоконференции для бизнеса и личного общения</div>
            */}
        </section>

        <section className="container mx-auto mt-8 xl:mt-20">
            <div className="px-2 xl:px-20 grid grid-cols-1 xl:grid-cols-2 gap-x-8 items-center justify-center">
                <div className="h-40 flex items-center justify-center">
                    <div className="text-left">
                        <div className="py-1 text-lg lg:text-xl">1. Нажмите "создать конференцию"</div>
                        <div className="py-1 text-lg lg:text-xl">2. Поделитесь ссылкой</div>
                        <div className="py-1 text-lg lg:text-xl">3. Начните встречу</div>
                    </div>
                </div>
                <div className="h-40 xl:border xl:shadow flex items-center justify-center">
                    <a href="/create" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded shadow">Создать конференцию</a>
                </div>
            </div>
        </section>
        
        <section className="container mx-auto mt-8 xl:mt-20">
            <div className="px-2 xl:px-20 grid grid-cols-1 xl:grid-cols-2 gap-x-8 items-center">
                <div className="h-40 xl:border xl:shadow flex flex-col xl:flex-row gap-x-0 xl:gap-x-4 gap-y-4 items-center justify-center">
                    <input type="text" required placeholder="Код конференции" className="px-3.5 py-3.5 xl:px-3.5 xl:py-3.5 border border-gray-600"></input>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded shadow">Войти в конференцию</button>
                </div>
                <div className="h-40 flex items-center justify-center">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-12"> 
                        <div className="py-1 text-lg lg:text-xl text-left">- Бесплатно и без регистрации</div>
                        <div className="py-1 text-lg lg:text-xl text-left">- Высокое качество</div>
                        <div className="py-1 text-lg lg:text-xl text-left">- Поддержка всех устройств</div>
                        <div className="py-1 text-lg lg:text-xl text-left">- Ваши данные под защитой</div>
                    </div>
                </div>
            </div>
        </section>

        <section className="container mx-auto grid grid-cols-3 gap-x-2 xl:gap-x-8 xl:w-2/3 xl:px-20 mt-8 xl:mt-20">
            <img className="object-cover w-full h-60 rounded-md xl:rounded-xl" src="conf1.jpg" />
            <img className="object-cover w-full h-60 rounded-md ml:rounded-xl" src="conf2.jpg" />
            <img className="object-cover w-full h-60 rounded-md xl:rounded-xl" src="conf3.jpg" />
        </section>
        
        <section className="container mx-auto text-center mt-20">
            <h2 className="text-2xl">Отзывы от наших пользователей:</h2>
            <div className="px-2 xl:px-40 grid grid-cols-1 xl:grid-cols-3 gap-x-8 gap-y-8 mt-10 ">
                <div className="border shadow flex flex-col justify-between">
                    <div className="my-4 mx-8 text-left">Очень удобно! Мне понравилось!<br /><br />На удивление создать конференцию получилось проще всех ожиданий.<br /><br />Если вы ещё не пробовали - рекомендую попробовать!</div>
                    <div className="my-4 mx-8 text-right">- Эдуард В.</div>
                </div>
                <div className="border shadow flex flex-col justify-between">
                    <div className="my-4 mx-8 text-left">Попробовали командой. Остановили своё выбор на данном продукте - это отличный инструменд для коммуникаций команды!<br /><br />Это то что мы искали и нам было нужно!</div>
                    <div className="my-4 mx-8 c text-right">- Валерий Д.</div>
                </div>
                <div className="border shadow flex flex-col justify-between">
                    <div className="my-4 mx-8 text-left">Такой лёгкости и интуитивно понятного интерфейса не хватало.<br /><br />Уже порекомендовал всем партнерам. И вы знаете - партнёры тоже довольны и благодарны!</div>
                    <div className="my-4 mx-8 text-right">- Исмаил К.</div>
                </div>
            </div>
        </section>
        
        <section className="container mx-auto text-center mt-40 mb-4">
            <a href="/user_agreement">Условаия использования</a> | <a href="/confidence_policy">Конфиденциальность</a> | <a href="/acceptable_use_of_service">Допустимое использование сервиса</a> | <a href="/legal_notices_and_compliance">Правовые положения и соответствие</a>
        </section>
    </div>
}

export default PHome;