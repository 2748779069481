let PCreate = () => {
    const confCode = '000 000 000';
    const confLink = 'https://confeye.ru/?c=000000000';
    const copyText = (e) => {
        const range = document.createRange();
        range.selectNodeContents(e.currentTarget);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        try {
            document.execCommand('copy');
        } catch (e) {}
        selection.removeAllRanges();
    }

    return <div class="container mx-auto">
        <section className="container mx-auto mt-20">
            <h1 className="text-4xl">Создание конференции</h1>  
        </section>

        <section className="container mx-auto mt-20 text-center w-2/3" >
            <div className="w-full text-center">
                <div className="text-xl text-left">Название конференции:</div>
                <div>
                    <input type="text" required placeholder="Например: Инвестиции в современном мире в современное время" className="px-3.5 py-2 w-full border border-gray-600"></input>
                </div>
            </div>
        </section>

        <section className="mt-20">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded shadow">Создать конференцию</button>
        </section>

        <section className="container mx-auto mt-20 text-center">
            <div className="text-xl">Код конференции:</div>
            <div className="text-4xl inline mt-20 px-8 py-0 border rounded cursor-pointer" onClick={copyText}>{confCode}</div>
        </section>

        <section className="container mx-auto mt-10">
            <div className="text-xl">Ссылка на конференцию</div>
            <div className="my-4"></div>
            <div className="text-4xl inline mt-8 px-8 py-0 border rounded cursor-pointer" onClick={copyText}>{confLink}</div>
        </section>


    </div>
}

export default PCreate;