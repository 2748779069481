import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import PHome from './page/PHome.js';
import PConfCreate from './page/PConfCreate.js';
import PConfRoom from './page/PConfRoom.js';

import PTextUA from './page/PTextUA.js';
import PTextCP from './page/PTextUA.js';
import PTextAUOS from './page/PTextAUOS.js';
import PTextLNAC from './page/PTextLNAC.js';

function App() {
  return (
    <Router>
      <div className="App">
      <Routes>
        <Route path="/" element={<PHome />} />
        <Route path="/create" element={<PConfCreate />} />
        <Route path="/conf" element={<PConfRoom />} />
        <Route path="/user_agreement" element={<PTextUA />} />
        <Route path="/confidence_policy" element={<PTextCP />} />
        <Route path="/acceptable_use_of_service" element={<PTextAUOS />} />
        <Route path="/legal_notices_and_compliance" element={<PTextLNAC />} />
      </Routes>
      </div>
    </Router>
  );
}

export default App;
